import { ProfileType } from '@graph/types/global.generated'
import { fontWeights } from '@ui/theme/typography'

const FONT_SWAP = 'swap'
const GOOGLE_FONT_API_HOST = 'https://fonts.googleapis.com'

export const DEFAULT_PAYMENT_REQUEST = {
  country: 'US',
  currency: 'usd',
  requestPayerName: true,
}

export const requestTypeLabels: { [key: string]: string } = {
  [ProfileType.Family]: 'Nanny Lane Payroll (PLUS)',
  [ProfileType.Nanny]: 'Nanny Lane PLUS',
}

export const GOOGLE_FONT_API_URL = `${GOOGLE_FONT_API_HOST}/css?family=Montserrat:${[
  fontWeights.regular,
  fontWeights.medium,
].join(',')}&display=${FONT_SWAP}`

export const STRIPE_ERROR_TYPES = ['card_error', 'validation_error', 'invalid_request_error']
