import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

class ApolloCacheCreator {
  static call() {
    return new InMemoryCache({
      possibleTypes: {
        Listing: [
          'NannyListing',
          'FamilyListing',
          'FamilyPairListing',
          'ShareListing',
          'N4hNannyListing',
        ],
        Profile: ['Nanny', 'Family', 'ShareNanny'],
      },
      typePolicies: {
        JourneyStep: {
          fields: {
            articles: {
              merge: true,
            },
          },
        },
        Viewer: {
          keyFields: [],
        },
        User: {
          fields: {
            recommended: relayStylePagination(['filters']),
            candidates: relayStylePagination(['filters']),
            conversations: relayStylePagination(['filters']),
          },
        },
        Listing: {
          fields: {
            messages: relayStylePagination(),
            candidateInvitations: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        NannyContract: {
          fields: {
            address: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Errors: { keyFields: false },
        FieldError: { keyFields: false },
        ListingRole: { keyFields: false },
        RedactedTextOffset: { keyFields: false },
        RedactedTextType: { keyFields: false },
        UnsubscribeField: { keyFields: false },
        Unsubscribed: { keyFields: false },
      },
    })
  }
}

export default ApolloCacheCreator
