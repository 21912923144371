import { useEffect, useState } from 'react'

interface Ramp {
  settings: {
    cp: string
  }
  setPath: (path: string) => Promise<any>
  addUnits: (units: any[]) => Promise<any>
  displayUnits: () => void
  que: (() => void)[]
  passiveMode: boolean
  destroyUnits: (id: string) => void
  getUnits: () => void
  loadCoreJS: boolean
}

declare global {
  interface Window {
    ramp: Ramp // there's no type definition for ramp, so I added my own
  }
}

const usePlaywire = () => {
  const [ramp, setRamp] = useState<Ramp | null>(null)

  useEffect(() => {
    const handlePlaywireInitialized = () => {
      setRamp(window.ramp)
    }

    if (typeof window !== 'undefined' && !!window.ramp && !!window.ramp.addUnits) {
      handlePlaywireInitialized()
      if (window.ramp.settings.cp !== 'ROS') {
        window.ramp.setPath('ROS')
      }
    }

    window.addEventListener('nl.playwireReady', handlePlaywireInitialized)

    return () => {
      window.removeEventListener('nl.playwireReady', handlePlaywireInitialized)
    }
  }, [])

  return ramp
}

export default usePlaywire
