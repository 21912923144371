import { ApolloClient } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { useRouter } from 'next/router'

import { CONFIRM_ACCOUNT_MODAL_ID } from 'app/constants'
import { refreshPromptPath, loginPath } from 'routes/static'

import ApolloClientBrowser from './ApolloClientBrowser'
import { handleGraphQlErrors } from './utils'

/**
 * Returns a browser ApolloClient instance with the cache hydrated with the
 * cached data from Apollo Client's server side instance. Pulls the data that
 * was added to pageProps using addApolloState in the page's GetServerSideProps
 *
 * @param pageProps
 * @returns apollo client for browser
 */
const useApolloClient = (initialApolloState: any): ApolloClient<any> => {
  const router = useRouter()
  const state = initialApolloState

  const unAuthorizedLink = onError(({ graphQLErrors }) => {
    const next = router.asPath
    handleGraphQlErrors(graphQLErrors, {
      onGraphQlValidationFailed: () => {
        window.location.href = `${refreshPromptPath}?next=${encodeURIComponent(next)}`
      },
      onLoggedOutUnauthorized: () => {
        router.push({
          pathname: loginPath,
          query: { next },
        })
      },
      onFullSessionRequired: () => {
        router.push(
          {
            pathname: router.asPath,
            query: { modal: CONFIRM_ACCOUNT_MODAL_ID },
          },
          undefined,
          { shallow: true }
        )
      },
    })
  })

  const client = ApolloClientBrowser.getInstance(state, { unAuthorizedLink })

  return client
}

export default useApolloClient
