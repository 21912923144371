import css from '@styled-system/css'
import { css as styledCss, createGlobalStyle } from 'styled-components'
import normalize from 'styled-normalize'

import { defaultLinkStyle } from '@ui/common/Link'

import { body } from './typography'

export const globalCss = styledCss`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    line-height: 1.5;
    margin: 0;
    padding: 0;

    ${body}
  }

  #__next {
    height: 100%;
  }

  input,
  textarea,
  select {
    line-height: inherit;
    ${body}

    &::placeholder {
      ${css({ color: 'grey.1' })}
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    ${body}
  }

  strong {
    ${css({ fontWeight: 'medium' })}
  }

  a {
    ${defaultLinkStyle}
  }

  address {
    font-style: normal;
  }

  ul,
  ol {
    padding-left: 14px;
    margin: 0;

    li {
      padding: 4px 0;
    }
  }
`

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${globalCss}
`

export default GlobalStyle
