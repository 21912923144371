import { ApolloClient, createHttpLink, ApolloLink } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { RetryLink } from '@apollo/client/link/retry'
import { sha256 } from 'crypto-hash'
import merge from 'deepmerge'

import { captureSentryException } from 'app/utils'
import { getAnonymousId } from 'middleware/addAnonymousId'

import ApolloCacheCreator from './ApolloCacheCreator'

type ApolloClientBrowserOptions = {
  unAuthorizedLink: ApolloLink
}

/**
 * Stores a singleton instance of ApolloClient
 * On the client-side we only want one instance of apollo client created
 */
let apolloClient: ApolloClient<any>

class ApolloClientBrowser {
  static getInstance(initialState = null, options: ApolloClientBrowserOptions) {
    const isServer = typeof window === 'undefined'

    // For SSR we should always use fresh instance of Apollo client.
    // For CSR we should create an instance of Apollo client and use it until the end of user session
    if (isServer) {
      return createInstance(initialState, options)
    } else {
      if (!apolloClient) {
        apolloClient = createInstance(initialState, options)
      }

      return apolloClient
    }
  }
}

const createInstance = (
  initialState = null,
  options: ApolloClientBrowserOptions
): ApolloClient<any> => {
  const _apolloClient = createApolloClient(options)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(initialState, existingCache)

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }

  return _apolloClient
}

const createApolloClient = (options: ApolloClientBrowserOptions) => {
  const queryMutationLink = ApolloLink.from([
    createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
    options.unAuthorizedLink,
    getRetryLink(),
    getHttpLink(),
  ])

  // Split the link to only handle queries and mutations
  const splitLink = ApolloLink.split(
    (operation) => {
      const operationType = operation.query.definitions[0]
      return (
        operationType.kind === 'OperationDefinition' && operationType.operation !== 'subscription'
      )
    },
    queryMutationLink,
    new ApolloLink((operation, _forward) => {
      captureSentryException(
        `Subscription operation is using wrong apollo client: ${operation.operationName}`
      )
      return null
    })
  )

  return new ApolloClient({
    name: 'nl-next (Client)',
    version: process.env.NEXT_PUBLIC_REVISION,
    link: splitLink,
    cache: ApolloCacheCreator.call(),
    connectToDevTools: true,
  })
}

const getHttpLink = () => {
  let headers = {} as Record<string, string>
  const anonymousId = getAnonymousId()

  if (!!anonymousId) {
    headers['X-Anonymous-Id'] = anonymousId
  }

  return createHttpLink({
    uri: process.env.NEXT_PUBLIC_API_URI,
    credentials: 'include',
    headers,
  })
}

const getRetryLink = () => {
  return new RetryLink({
    attempts: {
      retryIf: (error, operation) => {
        return error && operation.operationName && !operation.operationName.includes('Mutation')
      },
    },
  })
}

export default ApolloClientBrowser
