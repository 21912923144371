import { Maybe } from 'graphql/jsutils/Maybe'
import { Redirect } from 'next'

import { ViewerGlobalFragment } from '@graph/fragments/ViewerGlobal.generated'
import {
  FeatureLookupKey,
  ProductVariant,
  ProfileType,
  PaidTierCohort,
  Nanny,
  Family,
} from '@graph/types/global.generated'
import { redirectTo } from 'app/server'
import { backgroundCheckStatusPath } from 'routes/backgroundChecks'
import { contractPreviewPage } from 'routes/contract'
import {
  dashboardFamilyEssentialPath,
  dashboardFamilyPlansPath,
  dashboardFamilyPlusPath,
  dashboardPath,
  loginPath,
  onboardingPath,
  payrollStatusPath,
} from 'routes/static'
import { routeWithParams } from 'routes/utils'

type Viewer = Maybe<ViewerGlobalFragment>

export const isViewerLoggedIn = (viewer: Viewer) => !!viewer?.user?.id

export const isViewerNanny = (viewer: Viewer) => viewer?.user?.profile?.type === ProfileType.Nanny

export const isViewerFamily = (viewer: Viewer) => viewer?.user?.profile?.type === ProfileType.Family

export const isViewerSubscribed = (viewer: Viewer) => !!viewer?.user?.activeSubscription?.id

export const isViewerFullSession = (viewer: Viewer) => !!viewer?.user?.isFullSession

export const viewerHasListing = (viewer: Viewer) => !!viewer?.user?.profile?.listing

export const viewerHasProfile = (viewer: Viewer) => !!viewer?.user?.profile

export const isViewerPlus = (viewer: Viewer) =>
  viewer?.user?.activeSubscription?.product?.variant === ProductVariant.Plus

export const isViewerEssential = (viewer: Viewer) =>
  viewer?.user?.activeSubscription?.product?.variant === ProductVariant.Essential

export const isViewerHidden = (viewer: Viewer) => viewer?.user?.isDisabled ?? false

export const isViewerAdmin = (viewer: Viewer) => viewer?.user?.isAdmin ?? false

export const hasPayroll = (viewer: Viewer) =>
  isViewerFamily(viewer) && !!viewer?.user?.profile?.payroll?.id

export const getSingleFlowPath = (viewer: Viewer) => {
  if (viewer?.user?.isComplete) return null

  if (hasPayroll(viewer)) return payrollStatusPath

  const latestBackgroundCheck = viewer?.user?.requestedBackgroundChecks[0]
  if (!!latestBackgroundCheck) return backgroundCheckStatusPath(latestBackgroundCheck.id)

  const latestNannyContract = viewer?.user?.latestNannyContract
  if (!!viewer?.user?.profile && !viewer?.user?.profile?.listing && !!latestNannyContract)
    return contractPreviewPage(latestNannyContract.id)

  return null
}

export const hasExternalPayrollId = (viewer: Viewer) => {
  return isViewerFamily(viewer)
    ? !!(viewer?.user?.profile as Family)?.employer?.externalEmployerId
    : !!(viewer?.user?.profile as Nanny)?.employee?.externalEmployeeId
}

export const hasAccessToFeature = (viewer: Viewer, feature: FeatureLookupKey) => {
  return viewer?.user?.features.some(({ lookupKey }) => lookupKey === feature) ?? false
}
// Temp helpers for paid required experiment
export const isViewerControlCohort = (viewer: Viewer) => {
  // THis ensures if corhort isn't set then viewer is considered control
  if (!viewer?.user?.paidTierCohort) return true

  return viewer.user.paidTierCohort === PaidTierCohort.Control
}

export const isViewerIntroduceEssentialCohort = (viewer: Viewer) =>
  viewer?.user?.paidTierCohort === PaidTierCohort.IntroduceEssential

export const isViewerPaidRequiredCohort = (viewer: Viewer) =>
  viewer?.user?.paidTierCohort === PaidTierCohort.PaidRequired

/**
 * Viewer Page Validation Rules
 */
type PageViewerValidationRule = [
  (viewer: Viewer) => boolean,
  { redirect: Redirect } | { notFound: true },
]

export const redirectIfNotLoggedIn = ({
  returnUrl,
}: {
  returnUrl: string
}): PageViewerValidationRule => [
  (viewer: Viewer) => !isViewerLoggedIn(viewer),
  redirectTo(
    routeWithParams(loginPath, {
      next: returnUrl,
    })
  ),
]

export const redirectIfNotNanny = (options?: { redirectTo?: string }): PageViewerValidationRule => [
  (viewer: Viewer) => viewerHasProfile(viewer) && !isViewerNanny(viewer),
  redirectTo(options?.redirectTo ?? dashboardPath),
]

export const redirectIfNotFamily = (options?: {
  redirectTo?: string
}): PageViewerValidationRule => [
  (viewer: Viewer) => viewerHasProfile(viewer) && !isViewerFamily(viewer),
  redirectTo(options?.redirectTo ?? dashboardPath),
]

export const redirectIfDoesNotHaveAccessToFeature = (
  feature: FeatureLookupKey
): PageViewerValidationRule => [
  (viewer: Viewer) => !hasAccessToFeature(viewer, feature),
  redirectTo(dashboardPath),
]

export const redirectIfOnboardingIncomplete = (): PageViewerValidationRule => [
  (viewer: Viewer) => !(viewer?.user?.isComplete ?? false),
  redirectTo(onboardingPath),
]

export const redirectIfOnboardingComplete = (): PageViewerValidationRule => [
  (viewer: Viewer) => !!viewer?.user?.isComplete,
  redirectTo(dashboardPath),
]

export const redirectIfNotEssential = (): PageViewerValidationRule => [
  (viewer: Viewer) => !isViewerEssential(viewer),
  redirectTo(dashboardFamilyPlansPath),
]

export const redirectIfFamilyPlus = (): PageViewerValidationRule => [
  (viewer: Viewer) => isViewerPlus(viewer),
  redirectTo(dashboardFamilyPlusPath),
]

export const redirectIfFamilyEssential = (): PageViewerValidationRule => [
  (viewer: Viewer) => isViewerEssential(viewer),
  redirectTo(dashboardFamilyEssentialPath),
]

export const redirectIfNotPlus = (): PageViewerValidationRule => [
  (viewer: Viewer) => !isViewerPlus(viewer),
  redirectTo(dashboardPath),
]

export const redirectIfPayrollOnboardingComplete = (): PageViewerValidationRule => [
  (viewer: Viewer) => viewer?.user?.profile?.payroll?.isComplete ?? false,
  redirectTo(payrollStatusPath),
]

export const redirectIfProfileTypeMismatch = (
  profileTypeParam: string,
  options?: { redirectTo?: string }
): PageViewerValidationRule => [
  (viewer: Viewer) =>
    viewerHasProfile(viewer) && viewer?.user?.profile?.type.toLowerCase() !== profileTypeParam,
  redirectTo(options?.redirectTo ?? dashboardPath),
]

/**
 * Takes an array of validation rules and returns the first redirect response
 * for any failed validation, or null if all validations pass
 * @param validationRules Array of tuples containing validation function and redirect response
 * @returns Redirect response object or null
 */
export const handleInvalidViewerPageRequest = (
  viewer: Viewer,
  validationRules: PageViewerValidationRule[]
) => {
  for (const [predicate, response] of validationRules) {
    if (predicate(viewer)) {
      return response
    }
  }
  return null
}
