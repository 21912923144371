import type { NextRequest } from 'next/server'

export const isFileRequest = (req: NextRequest) => {
  // Static file paths that should be skipped
  const STATIC_PATHS = ['fonts', '_next', 'static', 'images', 'favicon']

  // Check if path starts with any static paths
  const isStaticPath = STATIC_PATHS.some((path) => req.nextUrl.pathname.startsWith(`/${path}`))

  // Check for file extensions that indicate non-page requests
  const hasFileExtension = /\.[a-zA-Z0-9]+$/.test(req.nextUrl.pathname)

  // Check for API routes
  const isApiRoute = req.nextUrl.pathname.startsWith('/api/')

  // Skip static paths, files with extensions, and API routes
  return isStaticPath || hasFileExtension || isApiRoute
}

// This handles the scenario documented here:\
// https://careguide.atlassian.net/wiki/x/BADDsg
export const isBuildIdMismatch = (req: NextRequest) => {
  // Early return for non-data requests
  if (!req.nextUrl.pathname.startsWith('/_next/data/')) {
    return false
  }

  // Split the path to get the build ID part
  const parts = req.nextUrl.pathname.split('/_next/data/')
  if (!parts[1]) return false
  const buildIdPart = parts[1].split('/')
  if (!buildIdPart[0] || buildIdPart[0].length < 1) return false
  const requestBuildId = buildIdPart[0]
  const currentBuildId = process.env.NEXT_PUBLIC_BUILD_ID

  console.log('***** isBuildIdMismatch *****')
  console.log({ requestBuildId, currentBuildId })

  // This will exit early in development mode
  if (!currentBuildId || requestBuildId === 'development') {
    return false
  }

  return requestBuildId !== currentBuildId
}
